<template>
    <div class="dashboard">
      <header>
        <h1>Панель Администравтора</h1>
        <button @click="logout" class="logout-button">Logout</button>
      </header>
  
      <div class="grid-container">
        <!-- Promo Codes Block -->
        <section class="block promo-codes">
          <h2>Промокоды:</h2>
          <div class="promo-codes-list">
            <ul>
              <li v-for="promo in promoCodes" :key="promo.id">
                {{ promo.code }} - 
                <span v-if="promo.promo_type === 'solo'">
                  {{ isPromoActive(promo.expired_at) ? 'Активирован' : 'Истёк' }} |
                </span>
                <span v-else>
                  Осталось: {{ promo.count }} активаций |
                </span>
                <span>
                    Действителен до: {{ formatDate(promo.expired_at) }}
                </span>
              </li>
            </ul>
          </div>
          <!-- Button for opening the promo creation modal -->
          <button @click="openPromoModal" class="create-promo-button">Создать новый</button>
          <!-- Button for creating a list of promo codes -->
          <button @click="openCreatePromoListModal" class="create-promo-button">Создать список промокодов</button>
        </section>
        <CreatePromoListModal :show="isCreatePromoListModalOpen" @close="closeCreatePromoListModal" />
        
  
        <!-- Purchase Sum Block with Tabs -->
        <section class="block purchases">
          <h2>Выручка за период:</h2>
          <div class="tabs">
            <button v-for="period in periods" :key="period" @click="fetchPurchaseSum(period)" :class="{ active: activePeriod === period }">
              {{ capitalize(period) }}
            </button>
          </div>
          <p>Всего: {{ totalPurchaseSum }} ₽</p>
        </section>
  
        <!-- Users Block with modal integration -->
        <section class="block users">
          <h2>Пользователи:</h2>
          <input v-model="searchQuery" type="text" placeholder="Поиск по email" @input="searchUsers" class="search-input" />
          <div class="user-list">
            <ul>
              <li v-for="user in filteredUsers" :key="user.id" @click="openUserModal(user.id)">
                {{ user.email }} - Баланс: {{ user.balance }}
              </li>
            </ul>
          </div>
        </section>
  
        <!-- Purchase Statistics Block with Tabs -->
        <section class="block purchases-details">
          <h2>Транзакции за период:</h2>
          <div class="tabs">
            <button v-for="period in periods" :key="period" @click="fetchPurchaseStats(period)" :class="{ active: activePeriod === period }">
              {{ capitalize(period) }}
            </button>
          </div>
          <div class="purchase-list">
            <ul>
              <li v-for="purchase in purchases" :key="purchase.created_at">
                Сумма: {{ purchase.amount }} - Дата: {{ new Date(purchase.createdd_at).toLocaleString() }}
              </li>
            </ul>
          </div>
        </section>
      </div>
  
      <!-- User Modal for detailed info -->
      <UserModal :show="isUserModalOpen" :userId="selectedUserId" @close="closeUserModal" />
  
      <!-- Create Promo Modal -->
      <CreatePromo :show="isCreatePromoModalOpen" @close="closePromoModal" @promoCreated="promoCreated" />
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie'; // Импортируем js-cookie
  import UserModal from './UserModal.vue';
  import CreatePromo from './CreatePromo.vue'; // Импортируем компонент для создания промокода
  import CreatePromoListModal from './CreatePromoListModal.vue'; // Импортируем новый компонент
  
  export default {
    data() {
      return {
        newPromoCode: '',
        promoCodes: [],
        orders: [],
        users: [],
        filteredUsers: [],
        purchases: [],
        totalPurchaseSum: 0, // Сумма покупок
        searchQuery: '',
        periods: ['today', 'week', 'month', 'year'],
        activePeriod: 'today',
        isUserModalOpen: false, // Управление модальным окном пользователя
        isCreatePromoModalOpen: false,  // Управление модальным окном создания промокода
        isCreatePromoListModalOpen: false,  // Управление модальным окном списка промокодов
        selectedUserId: null,  // ID выбранного пользователя для отображения информации
      };
    },
    methods: {
      logout() {
        Cookies.remove('token');
        Cookies.remove('userID');
        this.$router.push('/');
      },
      async fetchPromoCodes() {
        try {
          const token = Cookies.get('token');
          const response = await axios.get('https://taroback.helicop.su/isadmin/api/admin/getpromocodes', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.promoCodes = response.data;
        } catch (error) {
          console.error('Failed to fetch promo codes:', error);
        }
      },
      isPromoActive(expiredAt) {
        const expirationDate = new Date(expiredAt);
        const currentDate = new Date();
        return expirationDate > currentDate;
      },
      async fetchUsers() {
        try {
          const token = Cookies.get('token');
          const response = await axios.get('https://taroback.helicop.su/isadmin/api/admin/getusers', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.users = response.data;
          this.filteredUsers = this.users;
        } catch (error) {
          console.error('Failed to fetch users:', error);
        }
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', options); // Форматируем в 'дд.мм.гггг'
      },
      async fetchPurchaseStats(period) {
        try {
          this.activePeriod = period;
          const token = Cookies.get('token');
          const response = await axios.get(`https://taroback.helicop.su/isadmin/api/admin/getpurchase/${period}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.purchases = response.data;
        } catch (error) {
          console.error(`Failed to fetch purchases for ${period}:`, error);
        }
      },
      async fetchPurchaseSum(period) {
        try {
          this.activePeriod = period; // Устанавливаем активную вкладку
          const token = Cookies.get('token');
          const response = await axios.get(`https://taroback.helicop.su/isadmin/api/admin/getpurchase/${period}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.totalPurchaseSum = response.data.reduce((sum, purchase) => sum + purchase.amount, 0);
        } catch (error) {
          console.error(`Failed to fetch purchase sum for ${period}:`, error);
        }
      },
      searchUsers() {
        this.filteredUsers = this.users.filter(user =>
          user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
      openPromoModal() {
        this.isCreatePromoModalOpen = true;
      },
      closePromoModal() {
        this.isCreatePromoModalOpen = false;
      },
      openCreatePromoListModal() {
        this.isCreatePromoListModalOpen = true;  // Открываем модальное окно списка промокодов
      },
      closeCreatePromoListModal() {
        this.isCreatePromoListModalOpen = false;  // Закрываем модальное окно списка промокодов
      },
      promoCreated(promo) {
        this.promoCodes.push(promo);  // Обновляем список промокодов
      },
      capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      openUserModal(userId) {
        this.selectedUserId = userId;
        this.isUserModalOpen = true;
      },
      closeUserModal() {
        this.isUserModalOpen = false;
        this.selectedUserId = null;
      }
    },
    mounted() {
      this.fetchUsers();
      this.fetchPromoCodes();
      this.fetchPurchaseStats('today');
      this.fetchPurchaseSum('today'); // Загружаем сумму покупок за сегодня при инициализации
    },
    components: {
      UserModal,
      CreatePromo,  // Регистрируем компонент создания промокода
      CreatePromoListModal  // Регистрируем компонент создания списка промокодов
    }
  };
  </script>

  <style scoped>
  .dashboard {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logout-button {
    background-color: #ff4757;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .block {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    margin-bottom: 15px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    padding: 5px 0;
    cursor: pointer;
  }
  
  .promo-input {
    display: flex;
    margin-bottom: 10px;
  }
  
  .promo-input input {
    flex: 1;
    padding: 8px;
    margin-right: 5px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #3b3b3b;
    color: #fff;
  }
  
  .search-input::placeholder {
    color: #aaa;
  }
  
  .user-list {
    max-height: 200px;
    overflow-y: scroll;
  }
  
  .promo-codes-list {
    max-height: 200px;
    overflow-y: scroll;
  }
  
  .purchase-list {
    max-height: 200px;
    overflow-y: scroll;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 10px;
  }
  
  .tabs button {
    background-color: #333;
    color: #fff;
    padding: 10px;
    margin-right: 5px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .tabs button.active {
    background-color: #ff4757;
  }
  
  /* Your existing styles */
  .create-promo-button {
    background-color: #ff4757;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 20px;
  }
  
  .create-promo-button:hover {
    background-color: #e43d50;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  </style>
  