<template>
    <div v-if="show" class="modal">
      <div class="modal-content">
        <h2>Имя: {{ userInfo?.name }}</h2>
        <p>Email: {{ userInfo?.email || 'N/A' }}</p>
        <p>Баланс: {{ userInfo?.balance !== null ? userInfo?.balance : 0 }} ₽</p>
        <p>Зарегестрирован: {{ formatDate(userInfo?.created_at) }}</p>
  
        <!-- Tabs for Orders and Spreads -->
        <div class="tabs">
          <button @click="activeTab = 'orders'" :class="{ active: activeTab === 'orders' }">Пополнения</button>
          <button @click="fetchSpreads" :class="{ active: activeTab === 'spreads' }">Расклады</button>
        </div>
  
        <!-- Orders Block -->
        <div v-if="activeTab === 'orders'">
          <h3>Пополнения</h3>
          <ul>
            <li v-if="orders.length === 0">оплаты не найдены.</li>
            <li v-for="order in orders" :key="order.id">
              <strong>ID оплаты:</strong> {{ order.id }} | <strong>Сумма:</strong> {{ order.amount }} ₽ | 
              <strong>Статус:</strong> {{ order.status_pay ? 'Paid' : 'Unpaid' }}
            </li>
          </ul>
        </div>
  
        <!-- Spreads Block -->
        <div v-if="activeTab === 'spreads'">
          <h3>Расклады</h3>
          <ul>
            <li v-if="spreads.length === 0">Расклады не найдены.</li>
            <li v-for="spread in spreads" :key="spread.id" @click="toggleSpread(spread.id)">
              <div>
                <strong>Вопрос:</strong> {{ spread.question }} | 
                <strong>Дата:</strong> {{ formatDate(spread.created_at) }}
              </div>
              <div v-if="expandedSpreads.includes(spread.id)">
                <p><strong>Ответ:</strong> {{ spread.answer }}</p>
                <p><strong>Карты:</strong></p>
                <ul>
                  <li v-for="card in spread.cards" :key="card.id">
                    <img :src="card.image_url" :alt="card.name" class="tarot-card-image" />
                    <strong>{{ card.name }}</strong> ({{ card.suit }} - {{ card.type }}) - 
                    Yes/No: {{ card.yes_no }} - {{ card.reversed ? 'Reversed' : 'Upright' }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
  
        <button @click="closeModal">Закрыть</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean,
      userId: Number
    },
    data() {
      return {
        userInfo: null,
        orders: [],
        spreads: [],
        activeTab: 'orders',
        expandedSpreads: [] // Массив для отслеживания открытых раскладов
      };
    },
    methods: {
      async fetchUserInfo() {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`https://taroback.helicop.su/isadmin/api/admin/getuser/${this.userId}?fields=name,balance,email,created_at`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.userInfo = await response.json();
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      },
      async fetchOrders() {
        this.activeTab = 'orders';
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`https://taroback.helicop.su/isadmin/api/admin/getuser/${this.userId}?fields=orders`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = await response.json();
          this.orders = data.orders;
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      },
      async fetchSpreads() {
        this.activeTab = 'spreads'; // Переключаемся на вкладку "Расклады"
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`https://taroback.helicop.su/isadmin/api/admin/getuser/${this.userId}?fields=spreads`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = await response.json();
          this.spreads = data.spreads; // Заполняем данные раскладов
        } catch (error) {
          console.error('Error fetching spreads:', error);
        }
      },
      toggleSpread(spreadId) {
        if (this.expandedSpreads.includes(spreadId)) {
          this.expandedSpreads = this.expandedSpreads.filter(id => id !== spreadId);
        } else {
          this.expandedSpreads.push(spreadId);
        }
      },
      formatDate(dateStr) {
        if (!dateStr) return 'N/A';
        const date = new Date(dateStr);
        return date.toLocaleString();
      },
      closeModal() {
        this.$emit('close');
      }
    },
    watch: {
      show(newValue) {
        if (newValue && this.userId) {
          this.fetchUserInfo();
          this.fetchOrders(); // Загружаем заказы по умолчанию
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #131212;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
  }
  
  .tabs button.active {
    background-color: #ff4757;
    color: #fff;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .tarot-card-image {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }
  </style>
  