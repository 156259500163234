<template>
  <div class="login-container">
    <div class="login-box">
      <h1>Войти в панель</h1>
      <form @submit.prevent="login">
        <div class="input-group">
          <input v-model="email" type="email" placeholder="Email" required />
        </div>
        <div class="input-group">
          <input v-model="password" type="password" placeholder="Password" required />
        </div>
        <button type="submit" class="login-button">ВОЙТИ</button>
      </form>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie'; // Импортируем js-cookie для работы с куки

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://taroback.helicop.su/admin/authorize', {
          email: this.email,
          password: this.password,
        });
        if (response.data.status === 'ok') {
          // Сохраняем токен и userID в cookies вместо localStorage
          Cookies.set('token', response.data.token, { expires: 30 }); // Токен действует 30 дней
          Cookies.set('userID', response.data.userID, { expires: 30 });

          // Переход на страницу dashboard
          this.$router.push('/dashboard');
        } else {
          this.errorMessage = 'Неверные учетные данные. Попробуйте снова.';
        }
      } catch (error) {
        this.errorMessage = 'Ошибка авторизации. Пожалуйста, попробуйте снова.';
      }
    },
  },
};
</script>

<style scoped>
/* Общий стиль для страницы */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1e1e1e; /* Фон приложения */
  color: #fff; /* Текст белого цвета */
}

.login-box {
  background-color: #2c2c2c; /* Фон окна авторизации */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #3b3b3b;
  color: #fff;
  font-size: 16px;
}

input[type="email"]::placeholder,
input[type="password"]::placeholder {
  color: #aaa;
}

.login-button {
  background-color: #ff4757;
  color: #fff;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
}

.login-button:hover {
  background-color: #e43d50;
}

.error-message {
  color: #ff4757;
  margin-top: 20px;
}

/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  .login-box {
    padding: 20px;
  }
  h1 {
    font-size: 24px;
  }
  .login-button {
    font-size: 16px;
  }
}
</style>
