<template>
    <router-view/> <!-- Это место для отображения компонентов, связанных с маршрутизацией -->
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Убираем отступы у всего документа и задаем фон */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e; /* Задаем цвет фона */
}

/* Применяем стили к корневому элементу Vue */
#app {
  width: 100%;
  height: 100%;
  background-color: #1e1e1e; /* Задаем такой же цвет фона */
  display: flex;
  justify-content: center; /* Опционально: выравнивание содержимого по центру */
  align-items: center;
  flex-direction: column;
}

/* Можно также сбросить отступы для всех элементов */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

</style>
