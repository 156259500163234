<template>
    <div v-if="show" class="modal">
      <div class="modal-content">
        <h2>Введите параметры</h2>
        <form @submit.prevent="createPromoCode">
          <div class="form-group">
            <label for="code">Промо код:</label>
            <input v-model="form.code" type="text" id="code" required placeholder="промокод:" />
          </div>
          <div class="form-group">
            <label for="value">Сумма:</label>
            <input v-model="form.value" type="number" id="value" required placeholder="Discount value" min="1" />
          </div>
          <div class="form-group">
            <label for="promoType">Тип:</label>
            <select v-model="form.promo_type" id="promoType" required>
              <option value="group">group</option>
              <option value="solo">solo</option>
            </select>
          </div>
          <div class="form-group">
            <label for="counts">кол-во активаций:</label>
            <input v-model="form.counts" type="number" id="counts" required placeholder="Usage count" min="1" />
          </div>
  
          <div class="form-group">
            <label for="expired_at">Действителен до::</label>
            <!-- Используем vue3-datepicker -->
            <Datepicker v-model="form.expired_at" :input-class="'datepicker-input'" :wrap="true" />
          </div>
  
          <button type="submit" class="submit-button">Создать Промокод</button>
          <button type="button" @click="closeModal" class="cancel-button">Отмена</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from 'vue3-datepicker'; // Импортируем vue3-datepicker
  
  export default {
    props: {
      show: Boolean,
    },
    components: {
      Datepicker, // Подключаем компонент календаря
    },
    data() {
      return {
        form: {
          code: '',
          value: 10.0,
          promo_type: 'group',
          counts: 1,
          expired_at: null, // Для хранения выбранной даты
        },
      };
    },
    methods: {
      async createPromoCode() {
        try {
          const token = localStorage.getItem('token');
          if (!this.form.expired_at) {
            alert('Please select an expiration date and time.');
            return;
          }
  
          const expiredAtDate = new Date(this.form.expired_at);
          const [year, month, day, hour, minute] = [
            expiredAtDate.getFullYear(),
            expiredAtDate.getMonth() + 1,
            expiredAtDate.getDate(),
            expiredAtDate.getHours(),
            expiredAtDate.getMinutes(),
          ];
  
          const response = await fetch('https://taroback.helicop.su/isadmin/api/admin/createpromo', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              code: this.form.code,
              value: this.form.value,
              promo_type: this.form.promo_type,
              counts: this.form.counts,
              expired_at: [
                {
                  day,
                  month,
                  year,
                  hour,
                  minute,
                },
              ],
            }),
          });
  
          if (!response.ok) {
            throw new Error('Failed to create promo code.');
          }
  
          const result = await response.json();
          console.log('Promo code created:', result);
          this.$emit('promoCreated', result); // Отправляем событие после успешного создания
          this.closeModal();
        } catch (error) {
          console.error('Error creating promo code:', error);
          alert('Error creating promo code. Please try again.');
        }
      },
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #131212;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    background-color: #3b3b3b;
    color: #fff;
  }
  
  .submit-button {
    background-color: #ff4757;
    color: #fff;
    padding: 10px;
    width: 100%;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .cancel-button {
    background-color: #555;
    color: #fff;
    padding: 10px;
    width: 100%;
    border: none;
    cursor: pointer;
  }
  
  .cancel-button:hover,
  .submit-button:hover {
    background-color: #444;
  }
  
  .datepicker-input {
    background-color: #3b3b3b;
    color: #fff;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
  }
  </style>
  