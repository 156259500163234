<template>
    <div v-if="show" class="modal">
      <div class="modal-content">
        <h2>Создание списка промокодов</h2>
        
        <!-- Форма для создания промокодов -->
        <form @submit.prevent="submitForm" class="promo-form">
          <label for="prefix">Префикс:</label>
          <input type="text" v-model="prefix" required placeholder="Введите префикс" />
    
          <label for="value">Сумма:</label>
          <input type="number" v-model="value" required placeholder="Введите значение" />
    
          <label for="len_code">Длина кода:</label>
          <input type="number" v-model="lenCode" required placeholder="Введите длину кода" />
    
          <label for="count">Количество:</label>
          <input type="number" v-model="count" required placeholder="Введите количество" />
          
          <button type="submit" class="create-promo-list-button">Создать</button>
        </form>
    
        <button @click="closeModal" class="close-modal-button">Закрыть</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie'; // Импортируем js-cookie для работы с куки

  export default {
    props: {
      show: Boolean
    },
    data() {
      return {
        prefix: '',  // Префикс для промокодов
        value: 0,    // Значение промокодов
        lenCode: 10, // Длина кода
        count: 10    // Количество промокодов
      };
    },
    methods: {
      async submitForm() {
        try {
          const token = Cookies.get('token'); // Получаем токен из куки

          // Проверяем, что токен существует
          if (!token) {
            throw new Error("Токен не найден. Пожалуйста, выполните вход.");
          }

          const response = await axios.post('https://taroback.helicop.su/isadmin/api/admin/promo/generate', {
            prefix: this.prefix,
            value: this.value,
            len_code: this.lenCode,
            count: this.count
          }, {
            headers: {
              Authorization: `Bearer ${token}` // Используем токен в заголовке
            }
          });

          // Получаем ссылку на скачивание файла
          const downloadLink = response.data.link_for_download;
          this.downloadFile(downloadLink);
          this.closeModal();  // Закрываем модальное окно после успешной отправки
        } catch (error) {
          console.error("Ошибка при создании промокодов:", error);
        }
      },
      downloadFile(url) {
        // Автоматическое скачивание файла по ссылке
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop(); // Устанавливаем имя файла
        a.click();
      },
      closeModal() {
        this.$emit('close');
      }
    }
  };
</script>

  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .promo-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  label {
    font-size: 16px;
    color: #333;
  }
  
  input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
  }
  
  input:focus {
    outline: none;
    border-color: #ff4757;
  }
  
  .create-promo-list-button {
    background-color: #ff4757;
    color: #fff;
    padding: 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
  }
  
  .create-promo-list-button:hover {
    background-color: #e43d50;
  }
  
  .close-modal-button {
    background-color: #ccc;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
  }
  
  .close-modal-button:hover {
    background-color: #aaa;
  }
  
  /* Адаптация для мобильных устройств */
  @media (max-width: 768px) {
    .modal-content {
      padding: 15px;
      width: 90%;
    }
  
    h2 {
      font-size: 20px;
    }
  
    input {
      padding: 8px;
    }
  
    .create-promo-list-button, .close-modal-button {
      padding: 10px;
      font-size: 14px;
    }
  }
  </style>
  