import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/LoginPage.vue'; // Обнови путь, если нужно
import Dashboard from '../components/AdminDashboard.vue'; // Добавь другие компоненты, если необходимо

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  // Добавь другие маршруты, если нужно
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
